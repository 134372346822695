import LoadingSpinner from '@/components/LoadingSpinner'
import { ButtonHTMLAttributes, ReactElement } from 'react'

export const ButtonWithLoading = (props: { title: string; isLoading: boolean } & ButtonHTMLAttributes<HTMLButtonElement>): ReactElement => {
  const { disabled, isLoading, type, className, onClick, title, ...rest } = props
  return (
    <button
      {...rest}
      className={`flex items-center justify-center ${className || ''}`}
      disabled={isLoading ? true : disabled}
      type={type ?? 'button'}
      onClick={e => {
        if (!isLoading && onClick) {
          e.preventDefault()
          onClick(e)
        }
      }}
    >
      <LoadingSpinner className={`mr-2 h-5 w-5 text-amber-v1 ${isLoading ? 'block' : 'hidden'}`} />
      {title}
    </button>
  )
}

export default ButtonWithLoading
